.pagepadding{
    padding: 3% 5%;
}
.page-title{
    font-size: 30px;
}
.scroll{
    overflow-x: scroll;
}
.overlay
{
    height: 100vh;
    position: fixed;
    top: 0%;
    width: 100%;
    background: rgba(0,0,0,0.5);
    z-index: -1;
    opacity: 1;
    transition: 1s;
    display: none;
    cursor: pointer;
}
.images{
    height: 100%;
    width: 100%;
}
#modal
{
    height: 50vh;
    background-color: white;
    width: 50%;
    position: absolute;
    top: 44%;
    left: 50%;
    transform: translate(-50%,-50%); 
    box-shadow: 0px 0px 10px 3px grey;
    z-index: 1;
    transition: 2s;
    padding: 30px 40px;
    display: none;
    /* text-align: center; */
    overflow-y: auto;
}
@media screen and (max-width: 500px) {
    #modal{
        width: 100%;
    }
}
#modal h1{
    text-align: center;
}
#modal .pop{
    text-align: center;
}
.cross
{
    font-size: 30px;
    font-weight: 600;
    margin: 0%;
    position: absolute;
    right: 3%;
    top: 2%;
    cursor: pointer;
}
.label{
    text-align: left;
}
.btns{
    margin: 1%;
}