.open
{
    display: block;
}
.notopen{
    display: none;
}
.sub >div
{
    margin: 10px;
    height: 30px;
    cursor: pointer;

}
.sub >div>a{
    padding-top: 3px;

    height: 30px;
    width: 180px;
    text-align: left;
    cursor: pointer;
}
.sub >div:hover
{
    color: white;
    cursor: pointer;
}
