.main {
    margin-left: '20%';
    width: 800px;
    height: 600px;
    padding: 20px;
    text-align: center;

    border: 10px solid #61077c;
}
.center{
/* position: relative;
left: 5%; */
display: flex;
justify-content: center;
margin-bottom: 30px;
margin: 30px;
}
.first{
    background-image: url("../assets/img/img1.jpg");
     width: 750px;
      height: 550px; 
      padding: 20px;
       text-align: center;
        border: 5px solid rgb(230, 114, 168);
}
.second{
    font-size: 25px;
}
.third{
    font-size: 30px;
}
.first1{
    font-size: 25px;
}
.second2{
    font-size: 30px;
}
.third3{
    font-size: 20px;
}
.firstchild{
    font-size: 50px;
     font-weight: bold;
}
.one1{
     font-size: 25px;
}
.two2{
     font-size: 20px;
}