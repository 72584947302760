.flag {
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    background: no-repeat center/100% 100%;
    vertical-align: bottom;
    font-style: normal;
}
.flag-ad {
    background-image: url("flags/ad.png");
}

.flag-ae {
    background-image: url("flags/ae.png");
}

.flag-af {
    background-image: url("flags/af.png");
}

.flag-ag {
    background-image: url("flags/ag.png");
}

.flag-ai {
    background-image: url("flags/ai.png");
}

.flag-al {
    background-image: url("flags/al.png");
}

.flag-am {
    background-image: url("flags/am.png");
}

.flag-ao {
    background-image: url("flags/ao.png");
}

.flag-aq {
    background-image: url("flags/aq.html");
}

.flag-ar {
    background-image: url("flags/ar.png");
}

.flag-as {
    background-image: url("flags/as.png");
}

.flag-at {
    background-image: url("flags/at.png");
}

.flag-au {
    background-image: url("flags/au.png");
}

.flag-aw {
    background-image: url("flags/aw.png");
}

.flag-ax {
    background-image: url("flags/ax.png");
}

.flag-az {
    background-image: url("flags/az.png");
}

.flag-ba {
    background-image: url("flags/ba.png");
}

.flag-bb {
    background-image: url("flags/bb.png");
}

.flag-bd {
    background-image: url("flags/bd.png");
}

.flag-be {
    background-image: url("flags/be.png");
}

.flag-bf {
    background-image: url("flags/bf.png");
}

.flag-bg {
    background-image: url("flags/bg.png");
}

.flag-bh {
    background-image: url("flags/bh.png");
}

.flag-bi {
    background-image: url("flags/bi.png");
}

.flag-bj {
    background-image: url("flags/bj.png");
}

.flag-bl {
    background-image: url("flags/bl.png");
}

.flag-bm {
    background-image: url("flags/bm.png");
}

.flag-bn {
    background-image: url("flags/bn.png");
}

.flag-bo {
    background-image: url("flags/bo.png");
}

.flag-bq {
    background-image: url("flags/bq.png");
}

.flag-br {
    background-image: url("flags/br.png");
}

.flag-bs {
    background-image: url("flags/bs.png");
}

.flag-bt {
    background-image: url("flags/bt.png");
}

.flag-bv {
    background-image: url("flags/bv.png");
}

.flag-bw {
    background-image: url("flags/bw.png");
}

.flag-by {
    background-image: url("flags/by.png");
}

.flag-bz {
    background-image: url("flags/bz.png");
}

.flag-ca {
    background-image: url("flags/ca.png");
}

.flag-cc {
    background-image: url("flags/cc.png");
}

.flag-cd {
    background-image: url("flags/cd.png");
}

.flag-cf {
    background-image: url("flags/cf.png");
}

.flag-cg {
    background-image: url("flags/cg.png");
}

.flag-ch {
    background-image: url("flags/ch.png");
}

.flag-ci {
    background-image: url("flags/ci.png");
}

.flag-ck {
    background-image: url("flags/ck.png");
}

.flag-cl {
    background-image: url("flags/cl.png");
}

.flag-cm {
    background-image: url("flags/cm.png");
}

.flag-cn {
    background-image: url("flags/cn.png");
}

.flag-co {
    background-image: url("flags/co.png");
}

.flag-cr {
    background-image: url("flags/cr.png");
}

.flag-cu {
    background-image: url("flags/cu.png");
}

.flag-cv {
    background-image: url("flags/cv.png");
}

.flag-cw {
    background-image: url("flags/cw.png");
}

.flag-cx {
    background-image: url("flags/cx.png");
}

.flag-cy {
    background-image: url("flags/cy.png");
}

.flag-cz {
    background-image: url("flags/cz.png");
}

.flag-de {
    background-image: url("flags/de.png");
}

.flag-dj {
    background-image: url("flags/dj.png");
}

.flag-dk {
    background-image: url("flags/dk.png");
}

.flag-dm {
    background-image: url("flags/dm.png");
}

.flag-do {
    background-image: url("flags/do.png");
}

.flag-dz {
    background-image: url("flags/dz.png");
}

.flag-ec {
    background-image: url("flags/ec.png");
}

.flag-ee {
    background-image: url("flags/ee.png");
}

.flag-eg {
    background-image: url("flags/eg.png");
}

.flag-eh {
    background-image: url("flags/eh.html");
}

.flag-er {
    background-image: url("flags/er.png");
}

.flag-es {
    background-image: url("flags/es.png");
}

.flag-et {
    background-image: url("flags/et.png");
}

.flag-eu {
    background-image: url("flags/eu.html");
}

.flag-fi {
    background-image: url("flags/fi.png");
}

.flag-fj {
    background-image: url("flags/fj.png");
}

.flag-fk {
    background-image: url("flags/fk.png");
}

.flag-fm {
    background-image: url("flags/fm.png");
}

.flag-fo {
    background-image: url("flags/fo.png");
}

.flag-fr {
    background-image: url("flags/fr.png");
}

.flag-ga {
    background-image: url("flags/ga.png");
}

.flag-gb-eng {
    background-image: url("flags/gb-eng.html");
}

.flag-gb-nir {
    background-image: url("flags/gb-nir.html");
}

.flag-gb-sct {
    background-image: url("flags/gb-sct.html");
}

.flag-gb-wls {
    background-image: url("flags/gb-wls.html");
}

.flag-gb {
    background-image: url("flags/gb.png");
}

.flag-gd {
    background-image: url("flags/gd.png");
}

.flag-ge {
    background-image: url("flags/ge.png");
}

.flag-gf {
    background-image: url("flags/gf.png");
}

.flag-gg {
    background-image: url("flags/gg.png");
}

.flag-gh {
    background-image: url("flags/gh.png");
}

.flag-gi {
    background-image: url("flags/gi.png");
}

.flag-gl {
    background-image: url("flags/gl.png");
}

.flag-gm {
    background-image: url("flags/gm.png");
}

.flag-gn {
    background-image: url("flags/gn.png");
}

.flag-gp {
    background-image: url("flags/gp.png");
}

.flag-gq {
    background-image: url("flags/gq.png");
}

.flag-gr {
    background-image: url("flags/gr.png");
}

.flag-gs {
    background-image: url("flags/gs.png");
}

.flag-gt {
    background-image: url("flags/gt.png");
}

.flag-gu {
    background-image: url("flags/gu.png");
}

.flag-gw {
    background-image: url("flags/gw.png");
}

.flag-gy {
    background-image: url("flags/gy.png");
}

.flag-hk {
    background-image: url("flags/hk.png");
}

.flag-hm {
    background-image: url("flags/hm.html");
}

.flag-hn {
    background-image: url("flags/hn.png");
}

.flag-hr {
    background-image: url("flags/hr.png");
}

.flag-ht {
    background-image: url("flags/ht.png");
}

.flag-hu {
    background-image: url("flags/hu.png");
}

.flag-id {
    background-image: url("flags/id.png");
}

.flag-ie {
    background-image: url("flags/ie.png");
}

.flag-il {
    background-image: url("flags/il.png");
}

.flag-im {
    background-image: url("flags/im.png");
}

.flag-in {
    background-image: url("flags/in.png");
}

.flag-io {
    background-image: url("flags/io.png");
}

.flag-iq {
    background-image: url("flags/iq.png");
}

.flag-ir {
    background-image: url("flags/ir.png");
}

.flag-is {
    background-image: url("flags/is.png");
}

.flag-it {
    background-image: url("flags/it.png");
}

.flag-je {
    background-image: url("flags/je.png");
}

.flag-jm {
    background-image: url("flags/jm.png");
}

.flag-jo {
    background-image: url("flags/jo.png");
}

.flag-jp {
    background-image: url("flags/jp.png");
}

.flag-ke {
    background-image: url("flags/ke.png");
}

.flag-kg {
    background-image: url("flags/kg.png");
}

.flag-kh {
    background-image: url("flags/kh.png");
}

.flag-ki {
    background-image: url("flags/ki.png");
}

.flag-km {
    background-image: url("flags/km.png");
}

.flag-kn {
    background-image: url("flags/kn.png");
}

.flag-kp {
    background-image: url("flags/kp.png");
}

.flag-kr {
    background-image: url("flags/kr.png");
}

.flag-kw {
    background-image: url("flags/kw.png");
}

.flag-ky {
    background-image: url("flags/ky.png");
}

.flag-kz {
    background-image: url("flags/kz.png");
}

.flag-la {
    background-image: url("flags/la.png");
}

.flag-lb {
    background-image: url("flags/lb.png");
}

.flag-lc {
    background-image: url("flags/lc.png");
}

.flag-li {
    background-image: url("flags/li.png");
}

.flag-lk {
    background-image: url("flags/lk.png");
}

.flag-lr {
    background-image: url("flags/lr.png");
}

.flag-ls {
    background-image: url("flags/ls.png");
}

.flag-lt {
    background-image: url("flags/lt.png");
}

.flag-lu {
    background-image: url("flags/lu.png");
}

.flag-lv {
    background-image: url("flags/lv.png");
}

.flag-ly {
    background-image: url("flags/ly.png");
}

.flag-ma {
    background-image: url("flags/ma.png");
}

.flag-mc {
    background-image: url("flags/mc.png");
}

.flag-md {
    background-image: url("flags/md.png");
}

.flag-me {
    background-image: url("flags/me.png");
}

.flag-mf {
    background-image: url("flags/mf.html");
}

.flag-mg {
    background-image: url("flags/mg.png");
}

.flag-mh {
    background-image: url("flags/mh.png");
}

.flag-mk {
    background-image: url("flags/mk.png");
}

.flag-ml {
    background-image: url("flags/ml.png");
}

.flag-mm {
    background-image: url("flags/mm.png");
}

.flag-mn {
    background-image: url("flags/mn.png");
}

.flag-mo {
    background-image: url("flags/mo.png");
}

.flag-mp {
    background-image: url("flags/mp.png");
}

.flag-mq {
    background-image: url("flags/mq.png");
}

.flag-mr {
    background-image: url("flags/mr.png");
}

.flag-ms {
    background-image: url("flags/ms.png");
}

.flag-mt {
    background-image: url("flags/mt.png");
}

.flag-mu {
    background-image: url("flags/mu.png");
}

.flag-mv {
    background-image: url("flags/mv.png");
}

.flag-mw {
    background-image: url("flags/mw.png");
}

.flag-mx {
    background-image: url("flags/mx.png");
}

.flag-my {
    background-image: url("flags/my.png");
}

.flag-mz {
    background-image: url("flags/mz.png");
}

.flag-na {
    background-image: url("flags/na.png");
}

.flag-nc {
    background-image: url("flags/nc.png");
}

.flag-ne {
    background-image: url("flags/ne.png");
}

.flag-nf {
    background-image: url("flags/nf.png");
}

.flag-ng {
    background-image: url("flags/ng.png");
}

.flag-ni {
    background-image: url("flags/ni.png");
}

.flag-nl {
    background-image: url("flags/nl.png");
}

.flag-no {
    background-image: url("flags/no.png");
}

.flag-np {
    background-image: url("flags/np.png");
}

.flag-nr {
    background-image: url("flags/nr.png");
}

.flag-nu {
    background-image: url("flags/nu.png");
}

.flag-nz {
    background-image: url("flags/nz.png");
}

.flag-om {
    background-image: url("flags/om.png");
}

.flag-pa {
    background-image: url("flags/pa.png");
}

.flag-pe {
    background-image: url("flags/pe.png");
}

.flag-pf {
    background-image: url("flags/pf.png");
}

.flag-pg {
    background-image: url("flags/pg.png");
}

.flag-ph {
    background-image: url("flags/ph.png");
}

.flag-pk {
    background-image: url("flags/pk.png");
}

.flag-pl {
    background-image: url("flags/pl.png");
}

.flag-pm {
    background-image: url("flags/pm.png");
}

.flag-pn {
    background-image: url("flags/pn.png");
}

.flag-pr {
    background-image: url("flags/pr.png");
}

.flag-ps {
    background-image: url("flags/ps.png");
}

.flag-pt {
    background-image: url("flags/pt.png");
}

.flag-pw {
    background-image: url("flags/pw.png");
}

.flag-py {
    background-image: url("flags/py.png");
}

.flag-qa {
    background-image: url("flags/qa.png");
}

.flag-re {
    background-image: url("flags/re.png");
}

.flag-ro {
    background-image: url("flags/ro.png");
}

.flag-rs {
    background-image: url("flags/rs.png");
}

.flag-ru {
    background-image: url("flags/ru.png");
}

.flag-rw {
    background-image: url("flags/rw.png");
}

.flag-sa {
    background-image: url("flags/sa.png");
}

.flag-sb {
    background-image: url("flags/sb.png");
}

.flag-sc {
    background-image: url("flags/sc.png");
}

.flag-sd {
    background-image: url("flags/sd.png");
}

.flag-se {
    background-image: url("flags/se.png");
}

.flag-sg {
    background-image: url("flags/sg.png");
}

.flag-sh {
    background-image: url("flags/sh.png");
}

.flag-si {
    background-image: url("flags/si.png");
}

.flag-sj {
    background-image: url("flags/sj.html");
}

.flag-sk {
    background-image: url("flags/sk.png");
}

.flag-sl {
    background-image: url("flags/sl.png");
}

.flag-sm {
    background-image: url("flags/sm.png");
}

.flag-sn {
    background-image: url("flags/sn.png");
}

.flag-so {
    background-image: url("flags/so.png");
}

.flag-sr {
    background-image: url("flags/sr.png");
}

.flag-ss {
    background-image: url("flags/ss.png");
}

.flag-st {
    background-image: url("flags/st.png");
}

.flag-sv {
    background-image: url("flags/sv.png");
}

.flag-sx {
    background-image: url("flags/sx.png");
}

.flag-sy {
    background-image: url("flags/sy.png");
}

.flag-sz {
    background-image: url("flags/sz.png");
}

.flag-tc {
    background-image: url("flags/tc.png");
}

.flag-td {
    background-image: url("flags/td.png");
}

.flag-tf {
    background-image: url("flags/tf.png");
}

.flag-tg {
    background-image: url("flags/tg.png");
}

.flag-th {
    background-image: url("flags/th.png");
}

.flag-tj {
    background-image: url("flags/tj.png");
}

.flag-tk {
    background-image: url("flags/tk.png");
}

.flag-tl {
    background-image: url("flags/tl.png");
}

.flag-tm {
    background-image: url("flags/tm.png");
}

.flag-tn {
    background-image: url("flags/tn.png");
}

.flag-to {
    background-image: url("flags/to.png");
}

.flag-tr {
    background-image: url("flags/tr.png");
}

.flag-tt {
    background-image: url("flags/tt.png");
}

.flag-tv {
    background-image: url("flags/tv.png");
}

.flag-tw {
    background-image: url("flags/tw.png");
}

.flag-tz {
    background-image: url("flags/tz.png");
}

.flag-ua {
    background-image: url("flags/ua.png");
}

.flag-ug {
    background-image: url("flags/ug.png");
}

.flag-um {
    background-image: url("flags/um.png");
}

.flag-un {
    background-image: url("flags/un.html");
}

.flag-us {
    background-image: url("flags/us.png");
}

.flag-uy {
    background-image: url("flags/uy.png");
}

.flag-uz {
    background-image: url("flags/uz.png");
}

.flag-va {
    background-image: url("flags/va.png");
}

.flag-vc {
    background-image: url("flags/vc.png");
}

.flag-ve {
    background-image: url("flags/ve.png");
}

.flag-vg {
    background-image: url("flags/vg.png");
}

.flag-vi {
    background-image: url("flags/vi.png");
}

.flag-vn {
    background-image: url("flags/vn.png");
}

.flag-vu {
    background-image: url("flags/vu.png");
}

.flag-wf {
    background-image: url("flags/wf.png");
}

.flag-ws {
    background-image: url("flags/ws.png");
}

.flag-ye {
    background-image: url("flags/ye.png");
}

.flag-yt {
    background-image: url("flags/yt.png");
}

.flag-za {
    background-image: url("flags/za.png");
}

.flag-zm {
    background-image: url("flags/zm.png");
}

.flag-zw {
    background-image: url("flags/zw.png");
}

